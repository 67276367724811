// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero_hero__6MY\\+l {
  display: flex;
  flex-direction: row;
  height: 80vh;
  justify-content: center;
  align-items: center;
  gap: 50px;
  padding: 5rem 10rem;
  text-wrap: wrap;
}

.hero_hero_left__j14Jq {
  color: var(--secondary_color);
  font-family: "Inter", sans-serif;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
}

.hero_name__XyfQm {
  font-size: 36px;
}

.hero_slogan__XJ5J9 {
  font-size: 64px;
  font-family: "M PLUS Code Latin";
}

.hero_hero_right__yBYJV {
  width: 50%;
}

.hero_anim__oMtL4 {
  width: 90%;
  height: 90%;
}

.hero_SocialMediaIcons_group__zcRG4 {
  display: flex;
  gap: 1rem;
  flex-direction: row;
}`, "",{"version":3,"sources":["webpack://./src/scss/hero/hero.module.scss","webpack://./src/scss/utils/fonts.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;EACA,eAAA;AADF;;AAGA;EACE,6BAAA;EACA,gCCdoB;EDepB,UAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,SAAA;AAAF;;AAEA;EACE,eAAA;AACF;;AACA;EACE,eAAA;EACA,gCCxBsB;AD0BxB;;AAAA;EACE,UAAA;AAGF;;AADA;EACE,UAAA;EACA,WAAA;AAIF;;AAFA;EACE,aAAA;EACA,SAAA;EACA,mBAAA;AAKF","sourcesContent":["@use \"../utils/fonts.scss\" as *;\n\n.hero {\n  display: flex;\n  flex-direction: row;\n  height: 80vh;\n  justify-content: center;\n  align-items: center;\n  gap: 50px;\n  padding: 5rem 10rem;\n  text-wrap: wrap;\n}\n.hero_left {\n  color: var(--secondary_color);\n  font-family: $font-family-primary;\n  width: 60%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  gap: 2rem;\n}\n.name {\n  font-size: 36px;\n}\n.slogan {\n  font-size: 64px;\n  font-family: $font-family-secondary;\n}\n.hero_right {\n  width: 50%;\n}\n.anim {\n  width: 90%;\n  height: 90%;\n}\n.SocialMediaIcons_group {\n  display: flex;\n  gap: 1rem;\n  flex-direction: row;\n}\n","$font-family-primary: \"Inter\", sans-serif;\n\n$font-family-secondary: \"M PLUS Code Latin\";\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hero": `hero_hero__6MY+l`,
	"hero_left": `hero_hero_left__j14Jq`,
	"name": `hero_name__XyfQm`,
	"slogan": `hero_slogan__XJ5J9`,
	"hero_right": `hero_hero_right__yBYJV`,
	"anim": `hero_anim__oMtL4`,
	"SocialMediaIcons_group": `hero_SocialMediaIcons_group__zcRG4`
};
export default ___CSS_LOADER_EXPORT___;
