// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assests/images/bout_background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about_about__pQBK\\+ {
  height: 90vh;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  color: #0d1b2a;
}

.about_content__\\+opof {
  background-color: #f4faff;
  opacity: 0.85;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 3rem;
}

.about_title__vTwOS {
  font-size: 36px;
  font-family: "Inter", sans-serif;
}

.about_text__X9unw {
  font-size: 20px;
  width: 60%;
  word-spacing: 0.5rem;
  display: flex;
  gap: 30px;
  flex-direction: column;
  color: #0d1b2a;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/scss/about/about.module.scss","webpack://./src/scss/utils/_colors.scss","webpack://./src/scss/utils/fonts.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,mDAAA;EACA,2BAAA;EACA,4BAAA;EACA,sBAAA;EACA,kBAAA;EACA,cCTmB;ADQrB;;AAGA;EACE,yBCXoB;EDYpB,aAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,SAAA;AAAF;;AAEA;EACE,eAAA;EACA,gCEvBoB;AFwBtB;;AACA;EACE,eAAA;EACA,UAAA;EACA,oBAAA;EACA,aAAA;EACA,SAAA;EACA,sBAAA;EACA,cChCmB;EDiCnB,gCEjCoB;EFkCpB,gBAAA;AAEF","sourcesContent":["@use \"../utils/colors\" as *;\n@use \"../../scss/utils/fonts.scss\" as *;\n.about {\n  height: 90vh;\n  background: url(../../assests/images//bout_background.png);\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  text-align: center;\n  color: $text_color_dark;\n}\n.content {\n  background-color: $primary_color_light;\n  opacity: 0.85;\n  height: 100%;\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  gap: 3rem;\n}\n.title {\n  font-size: 36px;\n  font-family: $font-family-primary;\n}\n.text {\n  font-size: 20px;\n  width: 60%;\n  word-spacing: 0.5rem;\n  display: flex;\n  gap: 30px;\n  flex-direction: column;\n  color: $text_color_dark;\n  font-family: $font-family-primary;\n  font-weight: 400;\n}\n","$primary_color_dark: #0d1b2a;\n$primary_color_light: #f4faff;\n\n$black: #000;\n$white: #fff;\n\n$secondary_color_light: #f4faff;\n$secondary_color_dark: #0d1b2a;\n\n$third_color: #accbe1;\n\n$text_color_dark: $primary_color_dark;\n$text_color_light: $primary_color_light;\n\n$hover_color: #415a77;\n","$font-family-primary: \"Inter\", sans-serif;\n\n$font-family-secondary: \"M PLUS Code Latin\";\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"about": `about_about__pQBK+`,
	"content": `about_content__+opof`,
	"title": `about_title__vTwOS`,
	"text": `about_text__X9unw`
};
export default ___CSS_LOADER_EXPORT___;
