// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html[data-theme=dark] {
  --background-color: #0d1b2a;
  --color: #f4faff;
  --primary_color: #0d1b2a;
  --secondary_color: #f4faff;
  --font-family: \$font-family-primary;
}

html[data-theme=light] {
  --background-color: #f4faff;
  --color: #0d1b2a;
  --primary_color: #f4faff;
  --secondary_color: #0d1b2a;
  --font-family: \$font-family-primary;
}

.clients_main__\\+jPzO {
  padding: 30px 50px;
}

.clients_title__l\\+AU4 {
  font-size: 36px;
  font-family: "Inter", sans-serif;
  text-align: center;
  padding-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/scss/utils/_theme.scss","webpack://./src/scss/clients/clients.module.scss","webpack://./src/scss/utils/fonts.scss"],"names":[],"mappings":"AAGA;EACE,2BAAA;EACA,gBAAA;EAEA,wBAAA;EACA,0BAAA;EAEA,mCAAA;ACJF;;ADOA;EACE,2BAAA;EACA,gBAAA;EAEA,wBAAA;EACA,0BAAA;EAEA,mCAAA;ACNF;;AAVA;EAGI,kBAAA;AAWJ;;AAPA;EACI,eAAA;EACA,gCCbkB;EDclB,kBAAA;EACA,oBAAA;AAUJ","sourcesContent":["@use \"../utils/colors\" as *;\n@use \"../utils/fonts\" as *;\n\nhtml[data-theme=\"dark\"] {\n  --background-color: #{$primary_color_dark};\n  --color: #{$text_color_light};\n\n  --primary_color: #{$primary_color_dark};\n  --secondary_color: #{$secondary_color_light};\n\n  --font-family: $font-family-primary;\n}\n\nhtml[data-theme=\"light\"] {\n  --background-color: #{$primary_color_light};\n  --color: #{$text_color_dark};\n\n  --primary_color: #{$primary_color_light};\n  --secondary_color: #{$secondary_color_dark};\n\n  --font-family: $font-family-primary;\n}\n","@use \"../utils/colors\" as *;\n@use \"../utils/theme\" as *;\n@use \"../utils/fonts.scss\" as *;\n\n.main {\n    // width: 100vw;\n    // height: 30vh;\n    padding: 30px 50px;\n    // padding-bottom: 10px;\n}\n\n.title {\n    font-size: 36px;\n    font-family: $font-family-primary;\n    text-align: center;\n    padding-bottom: 20px;\n}","$font-family-primary: \"Inter\", sans-serif;\n\n$font-family-secondary: \"M PLUS Code Latin\";\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `clients_main__+jPzO`,
	"title": `clients_title__l+AU4`
};
export default ___CSS_LOADER_EXPORT___;
