// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html[data-theme=dark] {
  --background-color: #0d1b2a;
  --color: #f4faff;
  --primary_color: #0d1b2a;
  --secondary_color: #f4faff;
  --font-family: \$font-family-primary;
}

html[data-theme=light] {
  --background-color: #f4faff;
  --color: #0d1b2a;
  --primary_color: #f4faff;
  --secondary_color: #0d1b2a;
  --font-family: \$font-family-primary;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
  color: var(--textcolor);
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #415a77;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary_color);
  border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/scss/utils/_theme.scss","webpack://./src/scss/index.scss","webpack://./src/scss/utils/_colors.scss"],"names":[],"mappings":"AAGA;EACE,2BAAA;EACA,gBAAA;EAEA,wBAAA;EACA,0BAAA;EAEA,mCAAA;ACJF;;ADOA;EACE,2BAAA;EACA,gBAAA;EAEA,wBAAA;EACA,0BAAA;EAEA,mCAAA;ACNF;;AAZA;EACE,SAAA;EACA,UAAA;EACA,yCAAA;EACA,uBAAA;AAeF;;AAZA;EACE,UAAA;AAeF;;AAZA,UAAA;AACA;EACE,mBAAA;EACA,mBCFY;ADiBd;;AAZA,WAAA;AACA;EACE,kCAAA;EACA,mBAAA;AAeF","sourcesContent":["@use \"../utils/colors\" as *;\n@use \"../utils/fonts\" as *;\n\nhtml[data-theme=\"dark\"] {\n  --background-color: #{$primary_color_dark};\n  --color: #{$text_color_light};\n\n  --primary_color: #{$primary_color_dark};\n  --secondary_color: #{$secondary_color_light};\n\n  --font-family: $font-family-primary;\n}\n\nhtml[data-theme=\"light\"] {\n  --background-color: #{$primary_color_light};\n  --color: #{$text_color_dark};\n\n  --primary_color: #{$primary_color_light};\n  --secondary_color: #{$secondary_color_dark};\n\n  --font-family: $font-family-primary;\n}\n","@use \"./utils//theme\" as *;\n@use \"./utils//colors\" as *;\nbody {\n  margin: 0;\n  padding: 0;\n  background-color: var(--background-color);\n  color: var(--textcolor);\n}\n\n::-webkit-scrollbar {\n  width: 5px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n  border-radius: 10px;\n  background: $hover_color;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n  background: var(--secondary_color);\n  border-radius: 10px;\n}\n","$primary_color_dark: #0d1b2a;\n$primary_color_light: #f4faff;\n\n$black: #000;\n$white: #fff;\n\n$secondary_color_light: #f4faff;\n$secondary_color_dark: #0d1b2a;\n\n$third_color: #accbe1;\n\n$text_color_dark: $primary_color_dark;\n$text_color_light: $primary_color_light;\n\n$hover_color: #415a77;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
